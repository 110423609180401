<template>
  <div>
    <my-nav-bar
      title="签单收款明细"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-for="unpaidReceive in list" :key="unpaidReceive.id" class="item">
          <van-cell title="客户名称" :value="unpaidReceive.client.name" />
          <van-cell title="单据编号" :value="unpaidReceive.order ? unpaidReceive.order.num : unpaidReceive.after_order.num" />
          <van-cell value-class="cell-amount" title="收款金额" :value="'￥' + unpaidReceive.receive_amount" />
          <van-cell title="收款日期" :value="unpaidReceive.receive_date" />
          <van-cell title="收款人" :value="unpaidReceive.employee.name" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getUnpaidReceiveDetail } from '@/api/statistics'
import myNavBar from '@/components/my-nav-bar'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        ...this.$route.query,
        page: 0
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getUnpaidReceiveDetail(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
      }).catch(() => {
        this.finished = true
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .item {
    margin-bottom: 10px;
  }
  .item:nth-last-child(2) {
    margin-bottom: 0!important;
  }
</style>
